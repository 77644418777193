import axiosInstance from "../axiosInstance/axiosInstance";
import jwt_decode from "jwt-decode";

const setToken = (token) => {
  sessionStorage.setItem("token", token);
};

const getToken = () => {
  const token = sessionStorage.getItem("token");
  if (token) {
    return token;
  }
  return null;
};

const login = (userData) => {
  return axiosInstance.post(
    "https://api.certifieddump.com/api-admin/login",
    userData
  );
};

const getUserId = () => {
  const token = getToken();
  if (token) {
    const payload = jwt_decode(token);
    return payload?.userId;
  }
};

const getUserEmail = () => {
  const token = getToken();
  if (token) {
    const payLoad = jwt_decode(token);
    return payLoad?.email;
  }
  return null;
};

const getUserRole = () => {
  const token = getToken();
  if (token) {
    const payLoad = jwt_decode(token);
    return payLoad?.role;
  }
  return null;
};

const isLoggedIn = () => {
  const token = getToken();
  if (token) {
    const payLoad = jwt_decode(token);
    const isLogin = Date.now() < payLoad.exp * 1000;
    return isLogin;
  }
};

const logOut = () => {
  sessionStorage.clear();
};

export const authService = {
  logOut,
  getToken,
  setToken,
  login,
  getUserEmail,
  getUserRole,
  isLoggedIn,
  getUserId,
};
