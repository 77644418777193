import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: var(--green-100);
    border-left: 4px solid green;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;

  &:hover {
    background: green;
    cursor: pointer;
  }
`;

const SubMenu = ({ item, open }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const navigate = useNavigate();
  const signOut = async () => {
    sessionStorage.clear();
    navigate("/");
  };
  return (
    <>
      <SidebarLink
        name={item.title}
        to={item.path}
        onClick={() => {
          if (item.subNav) {
            showSubnav();
          }
          if (item.title === "Log out") {
            signOut();
          }
        }}
      >
        <div name={item.title}>
          <span>{item.icon}</span>
          <SidebarLabel className={open ? "" : "d-sidebar-menu-open"}>
            {item.title}
          </SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink name={item.title} to={item.path} key={index}>
              <span>{item.icon}</span>
              <SidebarLabel className={open ? "" : "d-sidebar-menu-open"}>
                {item.title}
              </SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
