import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./adminLogin.css";
import { authService } from "../../services/authService";
import toast from "react-hot-toast";

const AdminLogin = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userData = { username: user, password: password };
      const response = await authService.login(userData);
      sessionStorage.setItem("userId", response.data.user.id);
      sessionStorage.setItem("authToken", response.data.token);
      if (response?.data?.token) {
        authService.setToken(response?.data?.token);
      }
      if (response?.data?.msg) {
        toast.success("Logged in");
        navigate("/dashboard");
      }
      // console.log(response);
    } catch (error) {
      toast.error("Username or Password is Incorrect");
    }
  };

  return (
    <section>
      <div className="login container">
        <div className="login-form-wrap">
          <form className="login-form py-3" onSubmit={handleSubmit}>
            <h1 className="form-title my-4">Sign In</h1>
            <div className="form-group-s mb-3">
              <label htmlFor="username">Username:</label>
              <input
                className="form-control-s"
                type="text"
                id="username"
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />
            </div>
            <div className="form-group-s mb-3">
              <label htmlFor="password">Password:</label>
              <input
                className="form-control-s"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </div>
            <button className="form-button-s">Sign In</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AdminLogin;
