import { useRef, useState } from "react";
import axios from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import "./adminRegister.css";
import { authService } from "../../services/authService";
import toast from "react-hot-toast";

const Register = () => {
  const userRef = useRef();
  const token = authService.getToken();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const addData = {
        username: username,
        password: password,
      };
      // console.log(addData);
      await axios.post(
        "https://api.certifieddump.com/api-admin/signup",
        addData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // clear title
      setUsername("");
      setPassword("");
      toast.success("Signup successful");
      navigate("/login");
    } catch (error) {
      // console.error(error);
      toast.error("Unable to Signup");
    }
  };

  return (
    <section>
      <div className="signup container">
        <div className="signup-form-wrap ">
          <form className="signup-form py-3" onSubmit={handleSubmit}>
            <h1 className="form-title">Register</h1>
            <div className="form-group-s">
              <label htmlFor="username">Username:</label>
              <input
                className="form-control-s mb-3"
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                required
              />
            </div>

            <div className="form-group-s mb-3">
              <label htmlFor="password">Password:</label>
              <input
                className="form-control-s"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </div>

            <button className="form-button-s">Sign Up</button>
            <p
              className=""
              style={{
                fontWeight: "bolder",
                color: "black",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Already have an account yet?
              <Link
                to="/login"
                style={{ color: "var(--green-600", fontWeight: "light" }}
                className="signin"
              >
                Sign in
              </Link>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Register;
