import React from "react";
import "./Nav.css";
import logo from "../../pages/images/logo.png";
import windows from "../../pages/images/windows.png";
import { BiLogOutCircle, BiUserCircle } from "react-icons/bi";
import { PiCaretDownLight } from "react-icons/pi";
import { Outlet, Link } from "react-router-dom";

const Nav = () => {
  return (
    <>
      <nav className="d-user-nav">
        <div className="d-user-nav-items">
          <div className="d-nav-logo">
            <img loading="lazy" src={logo} alt="logo" className="" />
          </div>
          <div className="d-nav-cta">
            <div className="d-windows-img">
              <img loading="lazy" src={windows} alt="windows" />
            </div>
            <div>
              <p>Use PC application</p>
              <p>Get your e-books at your finger tips</p>
            </div>
          </div>
          {/* <Button /> */}
          <div className="d-user-dropdown">
            <BiUserCircle className="d-drop-icon" />
            <PiCaretDownLight className="d-drop-icon-2" />
            <div className="d-dropdown-inner">
              <div>
                <Link
                  to="/"
                  onClick={() => {
                    sessionStorage.clear();
                    // googleLogout();
                  }}
                >
                  <BiLogOutCircle /> <span>Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default Nav;
