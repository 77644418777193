import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./provider.css";
import axios from "axios";
import { authService } from "../../services/authService";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/base/Button";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import loader from "../images/colored-loader.gif";

export default function Books() {
  const [data, setData] = useState([]);
  const token = authService.getToken();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const formData = new FormData();
  const columns = [
    {
      field: "length",
      headerName: "S/N",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      maxWidth: 100,
      renderCell: (params) => {
        return (
          params.api.getRowIndexRelativeToVisibleRows(params.id) + 1
        ).toString();
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 400,
      renderCell: (params) => {
        const handleDelete = (id) => {
          if (token) {
            try {
              const response = axios.delete(
                `https://api.certifieddump.com/api-admin/deleteProvider/${params.row.id}`,
                { headers: { Authorization: `Bearer ${token}` } }
              );
              // console.log(response.data);
              toast.success("Book Deleted Successfully");
              getProvidersData();
            } catch (error) {
              // console.error(error);
              toast.error("Cannot Delete, Please Try Again Later");
            }
          }
        };

        const handleEdit = (params) => {
          setId(params.id);
          setName(params.name);
          setDescription(params.description);
          setUrl(params.url);
        };

        const Preview = (params) => {
          setId(params.id);
          setName(params.name);
          setUrl(params.url);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              htmlFor="my-modal-3"
              onClick={() => Preview(params.row)}
              className="book-buttons  py-1 px-4"
            >
              Preview Image
            </Button>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              htmlFor="my-modal-2"
              onClick={() => handleEdit(params.row)}
              className="book-buttons  py-1 px-4"
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleDelete}
              className="book-buttons  py-1 px-4"
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  const getProvidersData = async () => {
    axios
      .get("https://api.certifieddump.com/api-admin/getProviders", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data.providers);
        // console.log("Result:", res.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const AddProvider = async (event) => {
        setLoading(!loading);
    try {
      event.preventDefault();
      let response;
      if (selectedFile) {
        formData.append("file", selectedFile);
        response = await axios.post(
          "https://api.certifieddump.com/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res", response);
      }
      const addData = {
        id: id,
        name: name,
        description: description,
        url: response?.data?.path || url,
      };
      // console.log(addData);
      await axios.post(
        `https://api.certifieddump.com/api-admin/addProvider/${id}`,
        addData,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      // clear title
      setId("");
      setName("");
      setDescription("");
      setUrl("");
      getProvidersData();
      toast.success("Provider Added Successfully");
    } catch (error) {
      // console.error(error);
      toast.error("Cannot Add, Please Try Again Later");
    } finally {
      setLoading(false);
    }
  };

  const EditProvider = async (event) => {
        setLoading(!loading);
    try {
      event.preventDefault();
      let response;
      if (selectedFile) {
        formData.append("file", selectedFile);
        response = await axios.post(
          "https://api.certifieddump.com/upload",
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res", response);
      }
      const userData = {
        id: id,
        name: name,
        description: description,
        url: response?.data?.path ? response?.data?.path : url,
      };
      // console.log(userData);
      await axios.post(
        `https://api.certifieddump.com/api-admin/editProvider/${id}`,
        userData,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      // clear title
      setId("");
      setName("");
      setDescription("");
      setUrl("");
      toast.success("Provider Edited Successfully");
      getProvidersData();
    } catch (error) {
      // console.error(error);
      toast.error("Cannot provider now, Please Try Again Later");
    } finally {
      setLoading(false);
    }
  };

  const handleImgChange = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (token) {
      getProvidersData();
    }
  }, []);

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-3 provider-header"> Providers</h2>
      <div className="mt-3 mb-5 mx-5">
        <Button
          href="addbooks"
          className="px-4 py-2 addbook"
          data-bs-toggle="modal"
          data-bs-target="#exampleModals"
        >
          Add New Providers
        </Button>
      </div>
      {/* Table */}
      <Box
        className="bg-white"
        sx={{
          height: 600,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#4c6021",
            color: "white",
            paddingStart: "4rem !important",
          },

          "& .super-app-theme--cell": {
            paddingStart: "4rem !important",
          },
        }}
      >
        <DataGrid rows={data} columns={columns} />
      </Box>

      {/* Add provider pop up */}
      <div
        className="modal fade"
        id="exampleModals"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box mt="2rem">
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Add Provider
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <form className="w-[80%] md:w-[60%]" onSubmit={AddProvider}>
                      <Flex
                        direction="column"
                        justify="space-between"
                        align={["normal"]}
                        gap="1rem"
                        w="100%"
                      >
                        <Box className="box">
                          <FormControl isRequired required>
                            <FormLabel
                              htmlFor="name"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Name
                            </FormLabel>
                            <InputGroup>
                              <Input
                                as={Input}
                                id="name"
                                name="name"
                                focusBorderColor="#805AD5"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Enter provider name"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="image"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Image
                            </FormLabel>
                            <InputGroup>
                              <div>
                                <Input
                                  className="border px-3 py-[0.32rem]"
                                  type="file"
                                  id="image"
                                  name="image"
                                  onChange={handleImgChange}
                                />
                                <p className="inline-block text-neutral-700">
                                  Upload an image for provider cover
                                </p>
                              </div>
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box
                          mt="2rem"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="box"
                        >
                          {loading ? (
                            <div className="d-gif-div">
                              <img
                                src={loader}
                                alt="loading-gif"
                                className="d-gif"
                              />
                            </div>
                          ) : (
                            <Button
                              h="50px"
                              fontSize="1.2em"
                              w="100%"
                              type="submit"
                            >
                              Save
                            </Button>
                          )}
                        </Box>
                      </Flex>
                    </form>
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>

      {/* Edit provider pop up */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box mt="2rem">
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Edit Provider
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <form
                      className="w-[80%] md:w-[60%]"
                      onSubmit={EditProvider}
                    >
                      <Flex
                        direction="column"
                        justify="space-between"
                        align={["normal"]}
                        gap="1rem"
                        w="100%"
                      >
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="name"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Name
                            </FormLabel>
                            <InputGroup>
                              <Input
                                as={Input}
                                id="name"
                                name="name"
                                focusBorderColor="#805AD5"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Enter provider name"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="image"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Image
                            </FormLabel>
                            <InputGroup>
                              <div>
                                <Input
                                  className="border px-3 py-[0.32rem]"
                                  type="file"
                                  id="image"
                                  name="image"
                                  onChange={handleImgChange}
                                />
                                <p className="inline-block text-neutral-700">
                                  Upload an image for provider cover
                                </p>
                              </div>
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box
                          mt="2rem"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="box"
                        >
                          {loading ? (
                            <div className="d-gif-div">
                              <img
                                src={loader}
                                alt="loading-gif"
                                className="d-gif"
                              />
                            </div>
                          ) : (
                            <Button
                              h="50px"
                              fontSize="1.2em"
                              w="100%"
                              type="submit"
                            >
                              Save
                            </Button>
                          )}
                        </Box>
                      </Flex>
                    </form>
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>

      {/* pop up to Preview provider Image */}
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box>
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Provider Image
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                {/* Modal pop up to view image */}
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <Box className="my-2">{name}</Box>
                    {url ? (
                      <Box className="mb-2">
                        <img src={url} alt={name} />
                      </Box>
                    ) : (
                      <Box className="my-2">
                        There not Image attached to this provider
                      </Box>
                    )}
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
