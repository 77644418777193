import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./books.css";
import axios from "axios";
import { authService } from "../../services/authService";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/base/Button";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Text,
  Textarea,
  Select,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import loader from "../images/colored-loader.gif";

export default function Books() {
  const [data, setData] = useState([]);
  const token = authService.getToken();
  const [examData, setExamsData] = useState();
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [exams, setExams] = useState("");
  const [image, setImage] = useState("");
  const [language, setLanguage] = useState("");
  const [price, setPrice] = useState("");
  const [file, setFile] = useState("");
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const formData = new FormData();
  const docData = new FormData();
  const columns = [
    {
      field: "length",
      headerName: "S/N",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      maxWidth: 100,
      renderCell: (params) => {
        return (
          params.api.getRowIndexRelativeToVisibleRows(params.id) + 1
        ).toString();
      },
    },
    {
      field: "title",
      headerName: "Title",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 230,
    },
    {
      field: "exam",
      headerName: "Exam",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "price",
      headerName: "Price",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 400,
      renderCell: (params) => {
        const handleDelete = () => {
          if (token) {
            try {
              axios.delete(
                `https://api.certifieddump.com/api-admin/deleteBook/${params.row.id}`,
                { headers: { Authorization: `Bearer ${token}` } }
              );
              // console.log(response.data);
              getBooksData();
              toast.success("Book Deleted Successfully");
            } catch (error) {
              // console.error(error);
              toast.error("Cannot Delete, Please Try Again Later");
            }
          }
        };

        const handleEdit = (params) => {
          // console.log(params);
          setId(params.id);
          setTitle(params.title);
          setDescription(params.description);
          setFile(params.file);
          setImage(params.image);
          setLanguage(params.language);
          setPrice(params.price);
          setExams(params.exam);
        };

        const Preview = (params) => {
          setId(params.id);
          setTitle(params.title);
          setImage(params.image);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              htmlFor="my-modal-3"
              onClick={() => Preview(params.row)}
              className="book-buttons  py-1 px-4"
            >
              Preview Image
            </Button>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              htmlFor="my-modal-2"
              onClick={() => handleEdit(params.row)}
              className="book-buttons  py-1 px-4"
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleDelete}
              className="book-buttons  py-1 px-4"
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  const getBooksData = async () => {
    axios
      .get("https://api.certifieddump.com/api-admin/getBooks", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data.books);
        // console.log(res.data.books);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const AddBook = async (event) => {
    setLoading(!loading);
    try {
      event.preventDefault();
      let responsePromise, docPromise;

      if (selectedFile) {
        formData.append("file", selectedFile);
        responsePromise = await axios.post(
          "https://api.certifieddump.com/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res", response);
      }

      if (selectedDoc) {
        docData.append("file", selectedDoc);
        docPromise = await axios.post(
          "https://api.certifieddump.com/upload",
          docData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res", response);
      }
      const [response, doc] = await Promise.all([responsePromise, docPromise]);

      const addData = {
        title: title,
        description: description,
        file: doc?.data?.path || file,
        image: response?.data?.path || image,
        language: language,
        price: price,
        exam: exams,
      };
      // console.log(addData);
      await axios.post(
        "https://api.certifieddump.com/api-admin/addBook",
        addData,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      // clear title
      setTitle("");
      setDescription("");
      setImage("");
      setFile("");
      setLanguage("");
      setPrice("");
      setExams("");
      getBooksData();
      toast.success("Book Added Successfully");
    } catch (error) {
      // console.error(error);
      toast.error("Cannot add book now, Please Try Again Later");
    } finally {
      setLoading(false);
    }
  };

  const EditBook = async (event) => {
    setLoading(!loading);
    try {
      event.preventDefault();
      let responsePromise, docPromise;
      if (selectedFile) {
        formData.append("file", selectedFile);
        responsePromise = await axios.post(
          "https://api.certifieddump.com/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (selectedDoc) {
        docData.append("file", selectedDoc);
        docPromise = await axios.post(
          "https://api.certifieddump.com/upload",
          docData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      const [response, doc] = await Promise.all([responsePromise, docPromise]);
      // console.log(doc?.data?.path);
      // console.log(response?.data?.path);
      const userData = {
        title: title,
        description: description,
        file: doc?.data?.path ? doc?.data?.path : file,
        image: response?.data?.path ? response?.data?.path : image,
        language: language,
        price: price,
        exam: exams,
      };
      // console.log(userData);
      await axios.post(
        `https://api.certifieddump.com/api-admin/editBook/${id}`,
        userData,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      // console.log(updateRes);
      // clear title
      setTitle("");
      setDescription("");
      setImage("");
      setFile("");
      setLanguage("");
      setPrice("");
      setExams("");
      getBooksData();
      // console.log(data);
      toast.success("Book Edited Successfully");
    } catch (error) {
      // console.error(error);
      toast.error("Cannot edit book now, Please Try Again Later");
    } finally {
      setLoading(false);
    }
  };

  const handleImgChange = async (event) => {
    const image = event.target.files[0];
    if (image && image.type.startsWith("image/")) {
      setSelectedFile(event.target.files[0]);
    } else {
      toast.error("Please select a valid image file.");
    }
  };

  const handleDocChange = async (event) => {
    const document = event.target.files[0];
    const allowedTypes = ["application/pdf"];
    if (document && allowedTypes.includes(document.type)) {
      setSelectedDoc(document);
    } else {
      setSelectedDoc(null);
      toast.error("Please select a valid document file.");
    }
  };

  useEffect(() => {
    if (token) {
      getBooksData();
      axios
        .get("https://api.certifieddump.com/api-admin/getExams", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setExamsData(res.data.exams);
          // console.log("Result:", res.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, []);

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-3 books-header">Books</h2>
      {/* Orange Add book btn */}
      <div className="mt-3 mb-5 mx-5">
        <Button
          href="addbooks"
          className="px-4 py-2 addbook"
          data-bs-toggle="modal"
          data-bs-target="#exampleModals"
        >
          Add New Books
        </Button>
      </div>
      {/* Modal pop up to Add book*/}
      <div
        className="modal fade"
        id="exampleModals"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                {/*Add book Modal title */}
                <Box mt="2rem">
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Add Book
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                {/*Add book Modal body */}
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <form className="w-[80%] md:w-[60 %]" onSubmit={AddBook}>
                      <Flex
                        direction="column"
                        justify="space-between"
                        align={["normal"]}
                        gap="1rem"
                        w="100%"
                      >
                        <Box className="box">
                          <FormControl isRequired required>
                            <FormLabel
                              htmlFor="title"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Title
                            </FormLabel>
                            <InputGroup className="form-outline">
                              <Input
                                as={Input}
                                id="title"
                                name="title"
                                focusBorderColor="#805AD5"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Enter Book title"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="description"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Description
                            </FormLabel>
                            <InputGroup>
                              <Textarea
                                id="description"
                                name="description"
                                focusBorderColor="#805AD5"
                                type="textField"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                h="100px"
                                w="400px"
                                style={{ padding: "10px" }}
                                placeholder="Enter Description"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="image"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              File upload
                            </FormLabel>
                            <InputGroup>
                              <div>
                                <Input
                                  className="border px-3 py-[0.32rem]"
                                  type="file"
                                  id="image"
                                  name="image"
                                  onChange={handleDocChange}
                                />
                                <p className="inline-block text-neutral-700">
                                  Upload file here
                                </p>
                              </div>
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="image"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Image
                            </FormLabel>
                            <InputGroup>
                              <div>
                                <Input
                                  className="border px-3 py-[0.32rem]"
                                  type="file"
                                  id="image"
                                  name="image"
                                  accept="image/*"
                                  onChange={handleImgChange}
                                />
                                <p className="inline-block text-neutral-700">
                                  Upload an image of book-cover
                                </p>
                              </div>
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="language"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Language
                            </FormLabel>
                            <InputGroup>
                              <Input
                                as={Input}
                                id="language"
                                name="language"
                                focusBorderColor="#805AD5"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Enter Book language"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="language"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Price
                            </FormLabel>
                            <InputGroup>
                              <Input
                                as={Input}
                                id="price"
                                name="price"
                                focusBorderColor="#805AD5"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Enter Price"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="provider"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Exam
                            </FormLabel>
                            <Select
                              id="exam"
                              name="exam"
                              focusBorderColor="#805AD5"
                              onChange={(e) => setExams(e.target.value)}
                              type="text"
                              h="30px"
                              placeholder="Choose Exam"
                            >
                              {examData?.map((exam, i) => {
                                return (
                                  <option value={exam.title} key={i}>
                                    {exam.title}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          mt="2rem"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="box"
                        >
                          {loading ? (
                            <div className="d-gif-div">
                              <img
                                src={loader}
                                alt="loading-gif"
                                className="d-gif"
                              />
                            </div>
                          ) : (
                            <Button
                              h="50px"
                              fontSize="1.2em"
                              w="100%"
                              type="submit"
                            >
                              Save
                            </Button>
                          )}
                        </Box>
                      </Flex>
                    </form>
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>

      {/* Data Table */}
      <Box
        className="bg-white"
        sx={{
          height: 600,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#4c6021",
            color: "white",
            paddingStart: "2rem !important",
          },
          "& .super-app-theme--cell": {
            paddingStart: "2rem !important",
          },
        }}
      >
        <DataGrid rows={data} columns={columns} />
      </Box>

      {/* Edit book button section */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box>
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Edit Book
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                {/* Modal pop up to edit book */}
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <form className="w-[80%] md:w-[60%]" onSubmit={EditBook}>
                      <Flex
                        direction="column"
                        justify="space-between"
                        align={["normal"]}
                        gap="1rem"
                        w="100%"
                      >
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="title"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Title
                            </FormLabel>
                            <InputGroup className="form-outline">
                              <Input
                                as={Input}
                                id="title"
                                name="title"
                                focusBorderColor="#805AD5"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Enter Book title"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="description"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Description
                            </FormLabel>
                            <InputGroup>
                              <Textarea
                                id="description"
                                name="description"
                                focusBorderColor="#805AD5"
                                type="textField"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                h="100px"
                                w="400px"
                                style={{ padding: "10px" }}
                                placeholder="Enter Description"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="image"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              File upload
                            </FormLabel>
                            <InputGroup>
                              <div>
                                <Input
                                  className="border px-3 py-[0.32rem]"
                                  type="file"
                                  id="image"
                                  name="image"
                                  onChange={handleDocChange}
                                />
                                <p className="inline-block text-neutral-700">
                                  Upload file here
                                </p>
                              </div>
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="image"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Image
                            </FormLabel>
                            <InputGroup>
                              <div>
                                <Input
                                  className="border px-3 py-[0.32rem]"
                                  type="file"
                                  id="image"
                                  name="image"
                                  onChange={handleImgChange}
                                />
                                <p className="inline-block text-neutral-700">
                                  Upload an image of book-cover
                                </p>
                              </div>
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="language"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Language
                            </FormLabel>
                            <InputGroup>
                              <Input
                                as={Input}
                                id="language"
                                name="language"
                                focusBorderColor="#805AD5"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Enter Book language"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="language"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Price
                            </FormLabel>
                            <InputGroup>
                              <Input
                                as={Input}
                                id="price"
                                name="price"
                                focusBorderColor="#805AD5"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Enter Price"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="exam"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Exam
                            </FormLabel>
                            <Select
                              id="exams"
                              name="exams"
                              focusBorderColor="#805AD5"
                              onChange={(e) => setExams(e.target.value)}
                              type="text"
                              h="30px"
                              placeholder="Choose Exam"
                            >
                              {examData?.map((exam, i) => {
                                return (
                                  <option value={exam.title} key={i}>
                                    {exam.title}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          mt="2rem"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="box"
                        >
                          {loading ? (
                            <div className="d-gif-div">
                              <img
                                src={loader}
                                alt="loading-gif"
                                className="d-gif"
                              />
                            </div>
                          ) : (
                            <Button
                              h="50px"
                              fontSize="1.2em"
                              w="100%"
                              type="submit"
                            >
                              Save
                            </Button>
                          )}
                        </Box>
                      </Flex>
                    </form>
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>

      {/* pop up to preview book Image */}
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box>
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Book Image
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                {/* Modal pop up to view image */}
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <Box className="my-2">{title}</Box>
                    {image ? (
                      <Box className="mb-2">
                        <img src={image} alt={title} />
                      </Box>
                    ) : (
                      <Box className="my-2">
                        There not Image attached to this book
                      </Box>
                    )}
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
