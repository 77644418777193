import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "../App.css";
import { authService } from "../services/authService";
import axios from "axios";
import toast from "react-hot-toast";

const Rate = () => {
  const [rate, setRate] = useState("");
  const [curRate, setCurRate] = useState("");
  const token = authService.getToken();

  const GetRate = async () => {
    try {
      const res = await axios.get(
        "https://api.certifieddump.com/api-wallet/currency/9",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(res.data[0].rate);
      setCurRate(res.data[0].rate);
    } catch (error) {}
  };

  const SaveRate = async () => {
    try {
      await axios.post(
        "https://api.certifieddump.com/api-wallet/currency/9",
        {
          id : 9,
          currency: "USD",
          rate: rate,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      GetRate();
      toast.success("Rate set successfully!");
    } catch (error) {}
  };

  useEffect(() => {
    GetRate();
  }, []);

  return (
    <Layout>
      <h3 className="fx">Update dollar to naira rate</h3>
      <div className="fx-group">
        <span>1 dollar($) = </span>
        NGN{" "}
        <input
          type="number"
          value={rate}
          onChange={(e) => setRate(e.target.value)}
        />{" "}
        <button onClick={SaveRate}>Save</button>
        {curRate && (
          <p>
            Current rate is set to <strong>NGN{curRate}</strong> per dollar
          </p>
        )}
      </div>
    </Layout>
  );
};

export default Rate;
