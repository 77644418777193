import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { authService } from "../../services/authService";
import "./Overview.css";
import axios from "axios";

export default function Overview() {
  const [books, setBooks] = useState("");
  const [users, setUsers] = useState("");
  const [exams, setExams] = useState("");
  const token = authService.getToken();
  var getBooks = "https://api.certifieddump.com/api-admin/getBooks";
  var getUsers = "https://api.certifieddump.com/api-admin/all_users";
  var getExams = "https://api.certifieddump.com/api-admin/getExams";

  const data = [
    { name: "books", value: `${books?.length}` },
    { name: "exams", value: `${exams?.length}` },
    { name: "users", value: `${users?.length}` },
  ];

  const ticks = [];
  for (let i = 0; i <= 50; i++) {
    ticks.push(i);
  }
  const valueKey = "value";
  useEffect(() => {
    if (token) {
      axios
        .get(`  ${getBooks}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setBooks(res.data.books);
          // console.log("Result:", res.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token, getBooks, getUsers, getExams]);
  useEffect(() => {
    // console.log(authService.getToken());
    if (token) {
      axios
        .get(`  ${getUsers}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setUsers(res.data.users.result);
          // console.log("Result:", res.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token, getUsers]);
  useEffect(() => {
    // console.log(authService.getToken());
    if (token) {
      axios
        .get(`  ${getExams}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setExams(res.data.exams);
          // console.log("Result:", res.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token, getExams]);

  return (
    <div className="body">
      <Layout className="body">
        <h2 className="text-center pt-4 bar-header"> Overview</h2>
        <ResponsiveContainer width="85%" height={300} className="   bar-wrap">
          <BarChart data={data} className="bar pb-5 pt-2 ">
            <XAxis dataKey="name" stroke="black" />
            <YAxis interval={4} ticks={ticks} stroke="black" />
            <Tooltip />
            <Legend />
            <Bar dataKey={valueKey} fill="#ffaa33" />
          </BarChart>
        </ResponsiveContainer>
      </Layout>
    </div>
  );
}
