import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Transactions from "./pages/transactions/Transactions";
import Support from "./pages/support/Support";
import Chat from "./pages/chats/Chat";
import { Toaster } from "react-hot-toast";
import Users from "./pages/users/Users";
import Books from "./pages/books/Books";
import AdminRegister from "./components/adminRegister/AdminRegister";
import Overview from "./pages/overview/Overview";
import Providers from "./pages/provider/Providers";
import ContactUs from "./pages/contactUs/ContactUs";
import ProtectedRoute from "./pages/ProtectedRoutes";
import Annoucement from "./pages/annoucements/annoucement";
import Blog from "./pages/blog/Blog";
import Settings from "./pages/settings/Settings";
import Exams from "./pages/exams/exams";
import NotFound from "./NotFound";
import Rate from "./pages/Rate";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<App />}></Route>
      <Route path="registeranadmin" element={<AdminRegister />}></Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Overview />}></Route>
        <Route path="/exchange-rate" element={<Rate />}></Route>
        <Route path="library/books" element={<Books />} />
        <Route path="library/exams" element={<Exams />} />
        <Route path="library/providers" element={<Providers />} />
        <Route path="/users" element={<Users />}></Route>
        <Route path="transactions" element={<Transactions />} />
        <Route path="annoucements" element={<Annoucement />} />
        <Route path="blog" element={<Blog />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="support" element={<Support />} />
        <Route path="chat/:ticketId" element={<Chat />} />
        <Route path="settings" element={<Settings />} />
      </Route>
    </>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <Toaster position="top-center" />
      <RouterProvider router={router} />
    </React.StrictMode>
);
