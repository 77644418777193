import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { useState } from "react";
import "./Support.css";
import Tickets from "../../components/TicketList/Tickets";
import axios from "axios";
import { authService } from "../../services/authService";

const Support = () => {
  const [tickets, setTickets] = useState(null);
  const token = authService.getToken();
  const GetTickets = async () => {
    try {
      const response = await axios.get(
        `https://api.certifieddump.com/api/getSupportChat`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // console.log(response);
      setTickets(response.data.result);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("ticket-subject");
    GetTickets();
  }, []);

  return (
    <Layout>
      <section className="d-support">
        <div>
          <h1 className="support-header pt-5">Support Ticket</h1>
        </div>
        <div className="d-support-wrapper">
          <Tickets tickets={tickets} />
        </div>
      </section>
    </Layout>
  );
};

export default Support;
