import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./Users.css";
import axios from "axios";
import { authService } from "../../services/authService";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/base/Button";
import { Box } from "@chakra-ui/react";
import toast from "react-hot-toast";

export default function Users() {
  const [data, setData] = useState([]);
  const token = authService.getToken();
  const columns = [
    {
      field: "length",
      headerName: "S/N",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      maxWidth: 100,
      renderCell: (params) => {
        return (
          params.api.getRowIndexRelativeToVisibleRows(params.id) + 1
        ).toString();
      },
    },
    {
      field: "id",
      headerName: "User ID",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 300,
      padding: 3,
    },
    {
      field: "firstname",
      headerName: "Firstname",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "lastname",
      headerName: "Lastname",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "username",
      headerName: "Username",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 230,
    },
    {
      field: "phone_no",
      headerName: "Phone number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "last_login",
      headerName: "Last login",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = date.toLocaleDateString();
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "vstatus",
      headerName: "Vstatus",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "reg_date",
      headerName: "Registration date",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = date.toLocaleDateString();
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "dob",
      headerName: "Date of birth",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "country",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "gender",
      headerName: "Gender",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 230,
      renderCell: (params) => {
        const handleDelete = (id) => {
          if (token) {
            try {
              const response = axios.delete(
                `https://api.certifieddump.com/api-admin/user/${params.id}`,
                { headers: { Authorization: `Bearer ${token}` } }
              );
              // console.log(response.data);
              FetchAllUsers();
              toast.success("User Deleted Successfully");
            } catch (error) {
              // console.error(error);
              toast.error("Cannot Delete, Please Try Again Later");
            }
          }
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleDelete}
              className="book-buttons  py-1 px-4"
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  const FetchAllUsers = async () => {
    if (token) {
      await axios
        .get("https://api.certifieddump.com/api-admin/all_users", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setData(res.data.users.result);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  useEffect(() => {
    FetchAllUsers();
  }, []);

  return (
    <Layout>
      <h2 className="text-center py-3 user-header">Users</h2>
      <Box
        className="bg-white"
        sx={{
          height: 600,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#4c6021",
            color: "white",
            paddingStart: "4rem !important",
          },
          "& .super-app-theme--cell": {
            paddingStart: "4rem !important",
          },
        }}
      >
        <DataGrid rows={data} columns={columns} />
      </Box>
    </Layout>
  );
}
