import React from "react";
import "./Sidebar.css";
import logo from "../../pages/images/logo.png";
import { sideMenu } from "./SideMenu";
import SubMenu from "./SubMenu";
import { Outlet } from "react-router-dom";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { useState } from "react";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <span
        className={`${"d-side-handle"} ${
          open ? "d-close-side-handle" : "d-open-side-handle"
        }`}
        onClick={handleClick}
      >
        <AiOutlineDoubleLeft />
      </span>
      <aside
        className={`${"d-user-sidebar user-scroll"} ${
          open ? "d-user-sidebar-test" : "d-user-sidebar-mobile"
        } `}
      >
        <div className="d-sidebar-inner">
          <div className="d-sidebar-logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="d-sidebar-menu">
            {sideMenu.map((item, index) => {
              return (
                <SubMenu
                  // signOut={signOut}
                  title={item.title}
                  to={item.path}
                  item={item}
                  key={index}
                  open={open}
                >
                  <span>{item.icon}</span>
                  <p className={open ? "" : "d-sidebar-menu-open"}>
                    {item.title}
                  </p>
                </SubMenu>
              );
            })}
          </div>
        </div>
      </aside>
      <Outlet />
    </>
  );
};

export default Sidebar;
