import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./exams.css";
import axios from "axios";
import { authService } from "../../services/authService";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/base/Button";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Text,
  Textarea,
  Select,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import loader from "../images/colored-loader.gif";

export default function Exams() {
  const [data, setData] = useState([]);
  const token = authService.getToken();
  const [proData, setProData] = useState();
  const [booksData, setBooksData] = useState();
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [code, setCode] = useState("");
  const [providerid, setProviderId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const formData = new FormData();

  const columns = [
    {
      field: "length",
      headerName: "S/N",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      maxWidth: 100,
      renderCell: (params) => {
        return (
          params.api.getRowIndexRelativeToVisibleRows(params.id) + 1
        ).toString();
      },
    },
    {
      field: "title",
      headerName: "Exam",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 300,
    },
    {
      field: "providerid",
      headerName: "Provider",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 300,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 400,
      renderCell: (params) => {
        const handleDelete = () => {
          if (token) {
            try {
              const response = axios.delete(
                `https://api.certifieddump.com/api-admin/deleteExam/${params.row.id}`,
                { headers: { Authorization: `Bearer ${token}` } }
              );
              // console.log(response.data);
              toast.success("Exam Deleted Successfully");
              getExamsData();
            } catch (error) {
              // console.error(error);
              toast.error("Cannot Delete, Please Try Again Later");
            }
          }
        };

        const handleEdit = (params) => {
          setId(params.id);
          setTitle(params.title);
          setImage(params.image);
          setDescription(params.description);
          setPrice(params.price);
        };

        const Preview = (params) => {
          setId(params.id);
          setTitle(params.title);
          setImage(params.image);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              htmlFor="my-modal-3"
              onClick={() => Preview(params.row)}
              className="book-buttons  py-1 px-4"
            >
              Preview Image
            </Button>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              htmlFor="my-modal-2"
              onClick={() => handleEdit(params.row)}
              className="book-buttons  py-1 px-4"
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleDelete}
              className="book-buttons  py-1 px-4"
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  const getExamsData = async () => {
    axios
      .get("https://api.certifieddump.com/api-admin/getExams", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data.exams);
        // console.log("Result:", res.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const AddExam = async (event) => {
    setLoading(!loading);
    try {
      event.preventDefault();
      let response;
      if (selectedFile) {
        formData.append("file", selectedFile);
        response = await axios.post(
          "https://api.certifieddump.com/upload",
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res", response);
      }
      const addData = {
        title: title,
        image: response?.data?.path || image,
        description: description,
        price: price,
        providerid: providerid,
      };
      // console.log(addData);
      await axios.post(
        "https://api.certifieddump.com/api-admin/addExam",
        addData,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      // clear title
      setTitle("");
      setImage("");
      setDescription("");
      setPrice("");
      setProviderId("");
      getExamsData();
      toast.success("Exam Added Successfully");
    } catch (error) {
      // console.error(error);
      toast.error("Cannot add exam now, Please Try Again Later");
    } finally {
      setLoading(false);
    }
  };

  const EditExam = async (event) => {
    setLoading(!loading);

    try {
      event.preventDefault();
      let response;
      if (selectedFile) {
        formData.append("file", selectedFile);
        response = await axios.post(
          "https://api.certifieddump.com/upload",
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res", response);
      }
      const userData = {
        title: title,
        image: response?.data?.path ? response?.data?.path : image,
        description: description,
        price: price,
        providerid: providerid,
      };
      // console.log(userData);
      await axios.post(
        `https://api.certifieddump.com/api-admin/editExam/${id}`,
        userData,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      setTitle("");
      setImage("");
      setDescription("");
      setPrice("");
      setProviderId("");
      getExamsData();
      toast.success("Exam Edited Successfully");
    } catch (error) {
      // console.error(error);
      toast.error("Cannot Edit, Please Try Again Later");
    } finally {
      setLoading(false);
    }
  };

  const handleImgChange = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (token) {
      getExamsData();
      axios
        .get("https://api.certifieddump.com/api-admin/getProviders", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setProData(res.data.providers);
          // console.log("Result:", res.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, []);

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-3 exam-header"> Exams</h2>
      <div className="mt-3 mb-5 mx-5">
        <Button
          href="addbooks"
          className="px-4 py-2 addbook"
          data-bs-toggle="modal"
          data-bs-target="#exampleModals"
        >
          Add New Exams
        </Button>
      </div>
      {/* Table */}
      <Box
        className="bg-white"
        sx={{
          height: 600,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#4c6021",
            color: "white",
            paddingStart: "4rem !important",
          },
          "& .super-app-theme--cell": {
            paddingStart: "4rem !important",
          },
        }}
      >
        <DataGrid rows={data} columns={columns} />
      </Box>
      {/* Add Exam pop up */}
      <div
        className="modal fade"
        id="exampleModals"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box mt="2rem">
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Add Exam
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <form className="w-[80%] md:w-[60 %]" onSubmit={AddExam}>
                      <Flex
                        direction="column"
                        justify="space-between"
                        align={["normal"]}
                        gap="1rem"
                        w="100%"
                      >
                        <Box className="box">
                          <FormControl isRequired required>
                            <FormLabel
                              htmlFor="title"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Title
                            </FormLabel>
                            <InputGroup className="form-outline">
                              <Input
                                as={Input}
                                id="title"
                                name="title"
                                focusBorderColor="#805AD5"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Enter Exam title"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="description"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Description
                            </FormLabel>
                            <InputGroup>
                              <Textarea
                                id="description"
                                name="description"
                                focusBorderColor="#805AD5"
                                type="textField"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                h="100px"
                                w="400px"
                                style={{ padding: "10px" }}
                                placeholder="Enter Description"
                              />
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="image"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Image
                            </FormLabel>
                            <InputGroup>
                              <div>
                                <Input
                                  className="border px-3 py-[0.32rem]"
                                  type="file"
                                  id="image"
                                  name="image"
                                  onChange={handleImgChange}
                                />
                                <p className="inline-block text-neutral-700">
                                  Upload a display image for exam cover
                                </p>
                              </div>
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box className="box">
                          <FormControl>
                            <FormLabel
                              htmlFor="provider"
                              fontSize="1rem"
                              color="gray.600"
                            >
                              Provider
                            </FormLabel>
                            <Select
                              id="providerid"
                              name="providerid"
                              focusBorderColor="#805AD5"
                              onChange={(e) => setProviderId(e.target.value)}
                              type="text"
                              h="30px"
                              placeholder="Enter Provider"
                            >
                              {proData?.map((pro, i) => {
                                return (
                                  <option key={i} value={pro.name}>
                                    {pro.name}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          mt="2rem"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="box"
                        >
                          {" "}
                          {loading ? (
                            <div className="d-gif-div">
                              <img
                                src={loader}
                                alt="loading-gif"
                                className="d-gif"
                              />
                            </div>
                          ) : (
                            <Button
                              h="50px"
                              fontSize="1.2em"
                              w="100%"
                              type="submit"
                              colorscheme="purple"
                            >
                              Save
                            </Button>
                          )}
                        </Box>
                      </Flex>
                    </form>
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Exam pop up */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box>
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Edit Exam
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <>
                    <div className="modal-body">
                      <form className="w-[80%] md:w-[60 %]" onSubmit={EditExam}>
                        <Flex
                          direction="column"
                          justify="space-between"
                          align={["normal"]}
                          gap="1rem"
                          w="100%"
                        >
                          <Box className="box">
                            <FormControl>
                              <FormLabel
                                htmlFor="title"
                                fontSize="1rem"
                                color="gray.600"
                              >
                                Title
                              </FormLabel>
                              <InputGroup className="form-outline">
                                <Input
                                  as={Input}
                                  id="title"
                                  name="title"
                                  focusBorderColor="#805AD5"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                  type="text"
                                  h="30px"
                                  placeholder="Enter Exam title"
                                />
                              </InputGroup>
                            </FormControl>
                          </Box>
                          <Box className="box">
                            <FormControl>
                              <FormLabel
                                htmlFor="description"
                                fontSize="1rem"
                                color="gray.600"
                              >
                                Description
                              </FormLabel>
                              <InputGroup>
                                <Textarea
                                  id="description"
                                  name="description"
                                  focusBorderColor="#805AD5"
                                  type="textField"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  h="100px"
                                  w="400px"
                                  style={{ padding: "10px" }}
                                  placeholder="Enter Description"
                                />
                              </InputGroup>
                            </FormControl>
                          </Box>
                          <Box className="box">
                            <FormControl>
                              <FormLabel
                                htmlFor="image"
                                fontSize="1rem"
                                color="gray.600"
                              >
                                Image
                              </FormLabel>
                              <InputGroup>
                                <div>
                                  <Input
                                    className="border px-3 py-[0.32rem]"
                                    type="file"
                                    id="image"
                                    name="image"
                                    onChange={handleImgChange}
                                  />
                                  <p className="inline-block text-neutral-700">
                                    Upload an image for exam cover
                                  </p>
                                </div>
                              </InputGroup>
                            </FormControl>
                          </Box>
                          <Box className="box">
                            <FormControl>
                              <FormLabel
                                htmlFor="provider"
                                fontSize="1rem"
                                color="gray.600"
                              >
                                Provider
                              </FormLabel>

                              <Select
                                id="providerid"
                                name="providerid"
                                focusBorderColor="#805AD5"
                                onChange={(e) => setProviderId(e.target.value)}
                                type="text"
                                h="30px"
                                placeholder="Choose Provider"
                              >
                                {proData?.map((pro, i) => {
                                  return (
                                    <option key={i} value={pro.name}>
                                      {pro.name}
                                    </option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box
                            mt="2rem"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            className="box"
                          >
                            {loading ? (
                              <div className="d-gif-div">
                                <img
                                  src={loader}
                                  alt="loading-gif"
                                  className="d-gif"
                                />
                              </div>
                            ) : (
                              <Button
                                h="50px"
                                fontSize="1.2em"
                                w="100%"
                                type="submit"
                                colorscheme="purple"
                              >
                                Save
                              </Button>
                            )}
                          </Box>
                        </Flex>
                      </form>
                    </div>
                  </>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>

      {/* pop up to preview book Image */}
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box>
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Exam Image
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                {/* Modal pop up to view image */}
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <Box className="my-2">{title}</Box>
                    {image ? (
                      <Box className="mb-2">
                        <img src={image} alt={title} />
                      </Box>
                    ) : (
                      <Box className="my-2">
                        There not Image attached to this exam
                      </Box>
                    )}
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
