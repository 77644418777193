import React from "react";
import { Link } from "react-router-dom";
import Layout from "./components/Layout/Layout";

const NotFound = () => {
  return (
    <Layout>
      <div
        style={{ textAlign: "center", marginBottom: "1.6rem" }}
        className="mt-5"
      >
        <h2>Page Not Found</h2>
        <div>
          <p>Page not found.</p>
          <p>
            The above error occurred while the Web server was processing your
            request.
          </p>
          <p>
         <Link to="/dashboard" style={{color: "blue", textDecoration: "underline"}}>Go back to dashboard</Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
