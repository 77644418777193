import { GrOverview } from "react-icons/gr";
import { BiSupport } from "react-icons/bi";
import { ImLibrary } from "react-icons/im";
import { AiOutlineWallet } from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import {
  MdCurrencyExchange,
  MdOutlineContactSupport,
  MdOutlineSettingsPower,
} from "react-icons/md";
import { FaUser, FaBlog } from "react-icons/fa";
import { HiSpeakerphone } from "react-icons/hi";
import { IoMdSettings } from "react-icons/io";
import { PiExamFill } from "react-icons/pi";
import { GoSponsorTiers } from "react-icons/go";

export const sideMenu = [
  {
    title: "Overview",
    icon: <GrOverview />,
    path: "/dashboard",
  },
  {
    title: "Exchange rate",
    icon: <MdCurrencyExchange />,
    path: "/exchange-rate",
  },
  {
    title: "Library",
    icon: <ImLibrary />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Books",
        path: "/library/books",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Exams",
        path: "/library/exams",
        icon: <PiExamFill />,
      },
      {
        title: "Provider",
        path: "/library/providers",
        icon: <GoSponsorTiers />,
      },
    ],
  },
  {
    title: "Users",
    icon: <FaUser />,
    path: "/users",
  },
  {
    title: "Transactions",
    icon: <AiOutlineWallet />,
    path: "/transactions",
  },
  {
    title: "Annoucements",
    icon: <HiSpeakerphone />,
    path: "/annoucements",
  },
  {
    title: "Blog",
    icon: <FaBlog />,
    path: "/blog",
  },
  {
    title: "Contact Form",
    icon: <MdOutlineContactSupport />,
    path: "/contact",
  },
  {
    title: "Support & Help",
    icon: <BiSupport />,
    path: "/support",
  },
  {
    title: "Set Header Image",
    icon: <IoMdSettings />,
    path: "/settings",
  },
  {
    title: "Log out",
    icon: <MdOutlineSettingsPower />,
    path: "/",
  },
];
