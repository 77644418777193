import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./Transactions.css";
import axios from "axios";
import { authService } from "../../services/authService";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@chakra-ui/react";
import toast from "react-hot-toast";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


export default function Transactions() {
  const [reload, setreload] = useState(false);
  const VerifyPayment = async (ref) => {
    try {
      const response = await axios.get(
        `https://api.certifieddump.com/api-wallet/verifypayment?ref=${ref}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if(response.data?.msg){
        toast.success(response.data?.msg)
        
        getalltransaction()
        setreload()
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg||"Something went wrong please try again")
   
      getalltransaction()
      setreload()
    }
  };

  const [anchorEl, setAnchorEl] =useState(null);
  const [anchorEl1, setAnchorEl1] =useState("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>,a) => {
    console.log(a,event.currentTarget.className.includes(a.trx_id))
    setAnchorEl1(event.currentTarget.className)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async ( status,ref) => {
    console.log(ref)
    setAnchorEl(null);
    setAnchorEl1("")
    if(status){
      try {
        const response = await axios.post(
          `https://api.certifieddump.com/api-wallet/change-status/${ref.trx_id}`,{status},
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if(response.data?.msg){
          toast.success(response.data?.msg)
          
          getalltransaction()
          setreload()
        }
      } catch (error) {
        toast.error(error?.response?.data?.msg||"Something went wrong please try again")
     
        getalltransaction()
        setreload()
      }
    }
   

  };
  const columns = [
    {
      field: "length",
      headerName: "S/N",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      maxWidth: 100,
      renderCell: (params) => {
        return (
          params.api.getRowIndexRelativeToVisibleRows(params.id) + 1
        ).toString();
      },
    },
    {
      field: "trx_id",
      headerName: "Transaction ID",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "exam",
      headerName: "Exam",
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = date.toLocaleDateString();
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 300,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => {
        const ddd= params.row
        return <>
        {
  ddd.status==="Pending"&&
  
  // <span onClick={()=>VerifyPayment(params.row.trx_id)} className="btn bg-warning ">{params.value}</span>

  <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
   <span style={{color:"yellow"}}>{params.value}</span>
  <Button
  className={ddd.trx_id}
        id={ddd.trx_id}
        aria-controls={anchorEl!==null ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl!==null ? 'true' : undefined}
        onClick={(e)=>handleClick(e,ddd)}
      >
        Change status
      </Button>
      <Menu
        id={"basic-menu"+ddd.trx_id}
        anchorEl={anchorEl}
        open={anchorEl1.includes(ddd.trx_id)}
        onClose={()=>handleClose(null,null)}
        MenuListProps={{
          'aria-labelledby': ddd.trx_id,
        }}
      >
          <MenuItem onClick={()=>handleClose("Pending",ddd)}>Pending </MenuItem>
        <MenuItem onClick={()=>handleClose("Failed",ddd)}>Failed</MenuItem>
        <MenuItem onClick={()=>handleClose("successful",ddd)}>Successful</MenuItem>
      
       
      </Menu>
  </div>
        }
        {
  ddd.status==="Failed"&&
  <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
  <span style={{color:"red"}}>{params.value}</span>
  <Button
  className={ddd.trx_id}
         id={ddd.trx_id}
        aria-controls={anchorEl!==null ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl!==null ? 'true' : undefined}
        onClick={(e)=>handleClick(e,ddd)}
      >
        Change status
      </Button>
      <Menu
        id={"basic-menu"+ddd.trx_id}
        anchorEl={anchorEl}
      
        open={anchorEl1.includes(ddd.trx_id)}
        onClose={()=>handleClose(null,null)}
        MenuListProps={{
          'aria-labelledby': ddd.trx_id,
        }}
      ><MenuItem onClick={()=>handleClose("Pending",ddd)}>Pending</MenuItem>
      <MenuItem onClick={()=>handleClose("Failed",ddd)}>Failed</MenuItem>
      <MenuItem onClick={()=>handleClose("successful",ddd)}>Successful</MenuItem>
      </Menu>
  </div>
  
        }
        {
  ddd.status==="successful"&&
  <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
  <span style={{color:"green"}}>{params.value}</span>
  <Button
  className={ddd.trx_id}
         id={ddd.trx_id}
        aria-controls={anchorEl!==null ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl!==null ? 'true' : undefined}
        onClick={(e)=>handleClick(e,ddd)}
      >
        Change status
      </Button>
      <Menu
        id={"basic-menu"+ddd.trx_id}
        anchorEl={anchorEl}
        open={anchorEl1.includes(ddd.trx_id)}
        onClose={()=>handleClose(null,null)}
        MenuListProps={{
          'aria-labelledby': ddd.trx_id,
        }}
      >
        <MenuItem onClick={()=>handleClose("Pending",ddd)}>Pending</MenuItem>
        <MenuItem onClick={()=>handleClose("Failed",ddd)}>Failed</MenuItem>
        <MenuItem onClick={()=>handleClose("successful",ddd)}>Successful</MenuItem>
        {/* <MenuItem onClick={()=>handleClose()}>Logout</MenuItem> */}
      </Menu>
  </div>
  
        }
        </> 
      },
    },
  ];
  const [data, setData] = useState([]);
   
  
  const token = authService.getToken();

  useEffect(() => {
    if (token) {
      getalltransaction()
    }
  }, [reload]);

  const getalltransaction=()=>{
    axios
    .get("https://api.certifieddump.com/api-admin/alltransaction", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(res);
      setData(res.data.user_transactions);
      res.data.user_transactions.map((Trans) => {
        Trans.id = Trans.trx_id;
      });
      // console.log("Result:", res.data.user_transactions);
    })
    .catch((error) => {
      // console.log(error);
    });
  }

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-2 trans-header">Transactions</h2>
      <Box
        className="bg-white"
        sx={{
          height: 600,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#4c6021",
            color: "white",
            paddingStart: "4rem !important",
          },

          "& .super-app-theme--cell": {
            paddingStart: "4rem !important",
          },
        }}
      >
        <DataGrid
          className="table-style"
          style={{ backgroundColor: "white" }}
          rows={data}
          columns={columns}
        />
      </Box>
    </Layout>
  );
}
