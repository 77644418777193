import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./contact.css";
import axios from "axios";
import { authService } from "../../services/authService";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Text } from "@chakra-ui/react";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/base/Button";

export default function Exams() {
  const [data, setData] = useState([]);
  const token = authService.getToken();
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 250,
    },
    {
      field: "description",
      headerName: "Message",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 400,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 270,
      renderCell: (params) => {
        const Preview = (params) => {
          // console.log(params);
          setId(params.id);
          setName(params.name);
          setEmail(params.email);
          setMessage(params.description);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              htmlFor="my-modal-2"
              onClick={() => Preview(params.row)}
              className="book-buttons  py-1 px-4"
            >
              View Message
            </Button>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (token) {
      axios
        .get("https://api.certifieddump.com/api-admin/contactUs", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setData(res.data.result);
          // console.log("Result:", res.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4 contact-header">
        Message from Public
      </h2>
      <Box
        className="bg-white"
        sx={{
          height: "100%",
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#4c6021",
            color: "white",
            paddingStart: "4rem !important",
          },

          "& .super-app-theme--cell": {
            paddingStart: "4rem !important",
          },
        }}
      >
        <DataGrid rows={data} columns={columns} />
      </Box>

      {/* Show message pop up */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box>
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      User Message
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                {/* Modal pop up to edit book */}
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <Box className="my-2">{name}</Box>
                    <Box className="mb-2">{email}</Box>
                    <Box className="mb-2">{message}</Box>
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
