import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./settings.css";
import axios from "axios";
import { authService } from "../../services/authService";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/base/Button";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Text,
  Textarea,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import loader from "../images/colored-loader.gif";

export default function Settings() {
  const [data, setData] = useState([]);
  const token = authService.getToken();
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [image_url, setImageUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const formData = new FormData();

  const columns = [
    {
      field: "id",
      headerName: "Id",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 100,
      padding: 3,
    },
    {
      field: "title",
      headerName: "Title",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 170,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      minWidth: 300,
      renderCell: (params) => {
        const handleDelete = () => {
          if (token) {
            try {
              const response = axios.delete(
                `https://api.certifieddump.com/api-admin/deleteImageHeader/${params.row.id}`,
                { headers: { Authorization: `Bearer ${token}` } }
              );
              // console.log(response.data);
              getHeaderImage();
              toast.success("Image Deleted Successfully");
            } catch (error) {
              // console.error(error);
              toast.error("Cannot Delete, Please Try Again Later");
            }
          }
        };

        const handleEdit = (params) => {
          setId(params.id);
          setTitle(params.title);
          setImageUrl(params.image_url);
        };

        const Preview = (params) => {
          setId(params.id);
          setTitle(params.title);
          setImageUrl(params.image_url);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              htmlFor="my-modal-3"
              onClick={() => Preview(params.row)}
              className="book-buttons  py-1 px-4"
            >
              Preview Image
            </Button>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              htmlFor="my-modal-2"
              onClick={() => handleEdit(params.row)}
              className="book-buttons  py-1 px-4"
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleDelete}
              className="book-buttons  py-1 px-4"
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  const EditHeader = async (event) => {
    setLoading(!loading);
    try {
      event.preventDefault();
      let response;
      if (selectedFile) {
        formData.append("file", selectedFile);
        response = await axios.post(
          "https://api.certifieddump.com/upload",
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res", response);
      }
      const userData = {
        id: id,
        title: title,
        image_url: response.data.path || image_url,
      };
      // console.log(userData);
      await axios.post(
        `https://api.certifieddump.com/api-admin/editImageHeader/${id}`,
        userData,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      // clear title
      setId("");
      setTitle("");
      setImageUrl("");
      getHeaderImage();
      toast.success("Image Edited Successfully");
    } catch (error) {
      // console.error(error);
      toast.error("Cannot Edit, Please Try Again Later");
    } finally {
      setLoading(false);
    }
  };

  const AddHeader = async (event) => {
    setLoading(!loading);
    try {
      event.preventDefault();
      let response;
      if (selectedFile) {
        formData.append("file", selectedFile);
        response = await axios.post(
          "https://api.certifieddump.com/upload",
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res", response);
      }
      const addData = {
        id: id,
        title: title,
        image_url: response.data.path || image_url,
      };
      // console.log(addData);
      await axios.post(
        `https://api.certifieddump.com/api-admin/addImageHeader/${id}`,
        addData,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      setId("");
      setTitle("");
      setImageUrl("");
      getHeaderImage();
      toast.success("Image Added Successfully");
    } catch (error) {
      // console.error(error);
      toast.error("Cannot Add, Please Try Again Later");
    } finally {
      setLoading(false);
    }
  };

  const getHeaderImage = async () => {
    axios
      .get("https://api.certifieddump.com/api-admin/getImageHeader", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data.ImageHeader);
        // console.log("Result:", res.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (token) {
      getHeaderImage();
    }
  }, []);

  return (
    <Layout>
      <h2 className="text-center pt-4 set-header">Header Image</h2>
      <div className="mt-3 mb-5 mx-5">
        <Button
          href="addbooks"
          className="px-4 py-2 addbook"
          data-bs-toggle="modal"
          data-bs-target="#exampleModals"
        >
          Add Header Image
        </Button>
      </div>
      {/* Table */}
      <Box
        className="bg-white"
        sx={{
          height: 600,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#4c6021",
            color: "white",
            paddingStart: "4rem !important",
          },

          "& .super-app-theme--cell": {
            paddingStart: "4rem !important",
          },
        }}
      >
        <DataGrid
          className=""
          rows={data}
          columns={columns}
          // pageSize={12}
        />
      </Box>

      {/* Add Header Image Modal */}
      <div
        className="modal fade"
        id="exampleModals"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box mt="2rem">
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Add Header Image
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <>
                    <div className="modal-body">
                      <form className="w-[80%] md:w-[60%]" onSubmit={AddHeader}>
                        <Flex
                          direction="column"
                          justify="space-between"
                          align={["normal"]}
                          gap="1rem"
                          w="100%"
                        >
                          <Box>
                            <FormControl>
                              <FormLabel
                                htmlFor="name"
                                fontSize="1rem"
                                color="gray.600"
                              >
                                Title
                              </FormLabel>
                              <InputGroup>
                                <Input
                                  as={Input}
                                  id="title"
                                  name="title"
                                  focusBorderColor="#805AD5"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                  type="text"
                                  h="30px"
                                  placeholder="Enter Title"
                                />
                              </InputGroup>
                            </FormControl>
                          </Box>
                          <Box>
                            <FormControl>
                              <FormLabel
                                htmlFor="image"
                                fontSize="1rem"
                                color="gray.600"
                              >
                                Image
                              </FormLabel>
                              <InputGroup>
                                <div>
                                  <Input
                                    className="border px-3 py-[0.32rem]"
                                    type="file"
                                    id="image"
                                    name="image"
                                    onChange={handleFileChange}
                                  />
                                  <p className="inline-block text-neutral-700">
                                    Upload header image
                                  </p>
                                </div>
                              </InputGroup>
                            </FormControl>
                          </Box>

                          <Box
                            mt="2rem"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {loading ? (
                              <div className="d-gif-div">
                                <img
                                  src={loader}
                                  alt="loading-gif"
                                  className="d-gif"
                                />
                              </div>
                            ) : (
                              <Button
                                h="50px"
                                fontSize="1.2em"
                                w="100%"
                                type="submit"
                                colorscheme="purple"
                              >
                                Save
                              </Button>
                            )}
                          </Box>
                        </Flex>
                      </form>
                    </div>
                  </>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Header Image Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box mt="2rem">
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Edit Header Image
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <>
                    <div className="modal-body">
                      <form
                        className="w-[80%] md:w-[60%]"
                        onSubmit={EditHeader}
                      >
                        <Flex
                          direction="column"
                          justify="space-between"
                          align={["normal"]}
                          gap="1rem"
                          w="100%"
                        >
                          <Box>
                            <FormControl>
                              <FormLabel
                                htmlFor="name"
                                fontSize="1rem"
                                color="gray.600"
                              >
                                Title
                              </FormLabel>
                              <InputGroup>
                                <Input
                                  as={Input}
                                  id="title"
                                  name="title"
                                  focusBorderColor="#805AD5"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                  type="text"
                                  h="30px"
                                  placeholder="Enter Title"
                                />
                              </InputGroup>
                            </FormControl>
                          </Box>

                          <Box>
                            <FormControl>
                              <FormLabel
                                htmlFor="image"
                                fontSize="1rem"
                                color="gray.600"
                              >
                                Image
                              </FormLabel>
                              <InputGroup>
                                <div>
                                  <Input
                                    className="border px-3 py-[0.32rem]"
                                    type="file"
                                    id="image"
                                    name="image"
                                    onChange={handleFileChange}
                                  />
                                  <p className="inline-block text-neutral-700">
                                    Upload header image
                                  </p>
                                </div>
                              </InputGroup>
                            </FormControl>
                          </Box>

                          <Box
                            mt="2rem"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {loading ? (
                              <div className="d-gif-div">
                                <img
                                  src={loader}
                                  alt="loading-gif"
                                  className="d-gif"
                                />
                              </div>
                            ) : (
                              <Button
                                h="50px"
                                fontSize="1.2em"
                                w="100%"
                                type="submit"
                                colorscheme="purple"
                              >
                                Save
                              </Button>
                            )}
                          </Box>
                        </Flex>
                      </form>
                    </div>
                  </>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>

      {/* pop up to Preview blog Image */}
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="relative">
            <div className="mb-5  w-full relative  grid place-items-center bg-white modal-content">
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
              >
                <Box>
                  <div className="modal-header ">
                    <Text
                      mb="2rem"
                      fontSize="2rem"
                      color="gray.700"
                      className="pe-5 text-start"
                    >
                      Header Image Preview
                    </Text>
                    <button
                      type="button"
                      className="btn-close ps-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </Box>
                {/* Modal pop up to view image */}
                <Box
                  h="60%"
                  w="100%"
                  pr="10px"
                  pl="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="modal-body">
                    <Box className="my-2">{title}</Box>
                    {image_url ? (
                      <Box className="mb-2">
                        <img src={image_url} alt={title} />
                      </Box>
                    ) : (
                      <Box className="my-2">
                        There not Image attached to this header
                      </Box>
                    )}
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
